@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';


.ChartsPanel {
  position: fixed;
  display: flex;

  padding: layout.$padding--small;

  overflow: hidden;
  pointer-events: none;

  .szh-menu-button {
    border: 1px solid color.$black;
    border-radius: layout.$border-radius;
    padding: .3em layout.$padding--smallest;

    &:hover {
        opacity: .5;
    }
  }

  .charts {
      display: flex;
  }

  .ChartWrapper {
      flex: 1 1 100%;
    background: color.$white;

    border-radius: layout.$border-radius--small;
    border: 1px solid color.$blue--lightest;

    & > div {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .filter-toggle {
      .ReactForm__CheckboxSlider {
          margin-left: layout.$padding--small;
      }
  }

    .Panel {
        flex: 0 0 auto;
        margin: auto 0 0 auto;

        &.active {
            width: 100%;
            height: 100%;
        }
    }

  .chartFilter {
    display: inline-flex;
    z-index: 2;

    .filter {
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      margin-left: layout.$padding;
      padding: 0 layout.$padding--smallest 0 1px;

      font-size: font.$size--regular;
    }

    .indChartsLabel {
      border-radius: layout.$border-radius--small;
      border: 1px solid color.$grey;

      label {
        cursor: pointer;
        flex-shrink: 0;
        overflow: visible;
        white-space: pre;
        font-weight: font.$weight--medium;
        font-style: normal;
        color: color.$black;
        font-size: font.$size--small;
        letter-spacing: 0;
        line-height: 1;
        text-transform: uppercase;
        text-align: left;


        input {
          cursor: pointer;
          height: 17px;
          width: 17px;

        }

        .chartTitle {
          display: inline-block;
          line-height: 25px;
          vertical-align: top;
          margin-left: 3px;
        }
      }

      &.disabled {
        border-color: #ccc6f9;

        label {
          color: #ccc6f9;
        }
      }
    }
  }
}

.chartJsResponsiveParent {
  position: relative;
  width: 100%;
  height: 100%;
}
