@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';


.WelcomeModal {

    .ReactModal {
        &__window {
            text-align: center;
            padding: layout.$padding * 3;
        }
    }

    main {
        max-height: 90vh;
        padding: layout.$padding * 5;
    }

    header, footer {
        display: none;
    }

    h1 {
        margin-top: 0;

        font-size: font.$size--xlarge * 2;
        font-weight: font.$weight--medium;
        line-height: 1.5em;
    }

    img {
        margin-top: layout.$padding * 2;
    }

    p {
        width: 90%;
        max-width: 42.857rem;
        margin: layout.$padding * 3 auto 0;

        font-size: font.$size--large;
        font-weight: font.$weight--regular;;
        line-height: 1.5em;
        text-align: left;
    }

    button {
        display: inline-flex;
        align-items: center;

        width: max-content;
        margin-top: layout.$padding * 3;
        padding: layout.$padding--small layout.$padding;

        svg {
            stroke: color.$white;
            margin-left: layout.$padding--small;
        }
    }
}
